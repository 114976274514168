
section.services-section {
    .main-btn {
        padding: 13px 25px;
        min-width: 196px;
    }
    .btm-btn {
        padding: 13px 25px;
        min-width: 196px;
    }
    .wrap {
        padding: 45px 0 20px;
        min-height: 304px;
    }
    h5 {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.1em;
    }
    .h5 {
        font-size: 18px;
        color: #26282B;
        letter-spacing: 0.1em;
        font-weight: 700;
    }
}