section.hero-section {
    min-height: 400px;
    height: 1009px;

    .h1 {
        font-size: 65px;
        letter-spacing: 0.15em;
        font-weight: 300;
    }
    h3 {
        font-size: 35px;
        letter-spacing: 0.15em;
        font-weight: 700;
    }
    .btn {
        padding: 12px 35px;
    }
    .mod, .wrap {
        min-height: 400px;
        height: 1009px;
    }

    @media screen and (max-width: 1600px) {
        & {
            height: 620px;
        }
        .mod, .wrap {
            height: 620px;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            height: 53vw;
        }
        .mod, .wrap {
            height: 53vw;
        }
    }
}