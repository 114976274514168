/*
This is where you write custom SASS
*/

section.newTestMod .newTestMod-carousel {
    padding: 60px 14px 40px;
}
section.newTestMod .panel {
    border-radius: 0;
    border: 0;
    margin-bottom: 24px;
}
section.newTestMod .widget-body {
    background-color: #fff;
    -webkit-box-shadow: 0 8px 20px 2px  rgba($color: #000000, $alpha: 0.2);
    box-shadow: 0 8px 20px 2px rgba($color: #000000, $alpha: 0.2);
    padding: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
    position: relative;
    border-radius: 4px;
    -webkit-transition: .4s;
    transition: .4s;
    height: 270px;
    border-radius: 8px;
}
section.newTestMod .widget-img {
    position: absolute;
    width: 64px;
    height: 64px;
    left: 50%;
    margin-left: -32px;
    top: -32px;
}
section.newTestMod .img-border-light {
    box-shadow: 0 0 0 4px #fff;
    border-radius: 50px;
}
section.newTestMod .img-border {
    box-shadow: 0 0 0 4px rgba(0,0,0,0.1);
}
section.newTestMod .mar-no {
    margin: 0 !important;
}
section.newTestMod .h4 {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 700;
    margin: 0;
    word-break: break-word;
    padding-top: 20px;
    text-transform: capitalize;
}
section.newTestMod .h4 a {
    color: #333;
    text-decoration: underline !important;
}
section.newTestMod .h4 a:hover {
    text-decoration: none !important;
}
section.newTestMod i {
    color: #f7b80c;
}
section.newTestMod .text-muted, section.newTestMod a.text-muted:hover, section.newTestMod a.text-muted:focus, section.newTestMod a.text-muted:focus {
    color: #606060;
}
section.newTestMod .mar-btm {
    margin-bottom: 15px;
}
section.newTestMod .pad-ver {
    padding-bottom: 15px;
    font-size: 18px;
    line-height: 23px;
}
section.newTestMod .mw {
    max-width: 1465px;
    margin: 0 auto;
}
// section.newTestMod .bg-primary {
//     box-shadow: 5px 10px 80px rgba($color: #333, $alpha: 1.0) inset;
// }
@media (max-width: 1400px) {
    section.newTestMod .widget-body {
        height: 305px !important;
    }
}
@media (max-width: 992px) {
    section.newTestMod .widget-body {
        height: 267px !important;
    }
}
@media (max-width: 768px) {
    section.newTestMod .widget-body {
        height: auto !important;
        min-height: 250px;
    }
}
