section.contact-form {
    .custom-control-label {
        color: #ffffff !important;
        font-size: 18px !important;
    }
    .addr {
        a {
            letter-spacing: 0.02em;
            line-height: 30px;
        }
    }

    .btn {
        padding: 13px 25px;
    }
    
    ::placeholder {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.15em;
    }

    .form-control {
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        letter-spacing: 0.15em;
        border: none;
    }

    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }

    textarea {
        min-height: 142px;
    }
    
    [id*="btnSend"] {
        max-width: 196px;
    }
    
    @media screen and (max-width: 1800px) {
        .qc-form-wrapper {
            padding: 0;
        }
    }
    @media screen and (max-width: 1600px) {
        .qc-form-wrapper {
            padding: 0;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            padding-top: 0;
        }
    }
    @media screen and (max-width: 991px) {
        .qc-form-wrapper {
            margin-bottom: 0;
            padding: 50px 0 0 0;
        }
    }
    @media screen and (max-width: 767px) {}
}
